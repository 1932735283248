import React from "react";
import { Button } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import { Link as RouterLink } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import makeStyles from "@mui/styles/makeStyles";
import StickyBox from "react-sticky-box";

const DRAWER_WIDTH = "120px";

const pages = [
    { icon: <HomeIcon />, label: "Home", href: "/" },
    { icon: <ArticleIcon />, label: "CV", href: "/cv" },
];

const useStyles = makeStyles(() => ({
    drawer: {
        background: "#222",
        borderRadius: "5px",
        margin: "8px",
        overflow: 'hidden',
    },
    menuButton: {
        color: "whitesmoke",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        justifyContent: "space-between",
        minWidth: DRAWER_WIDTH,
        textAlign: "justify",
    }
}));

export default function Drawer() {
    const AnimatedStickyBox = animated(StickyBox);
    const AnimatedChevronLeft = animated(ChevronLeft);
    const [open, setOpen] = React.useState(false);
    const { drawer, menuButton } = useStyles();
    const drawerSpring = useSpring({
        width: open ? DRAWER_WIDTH : "39px"
    })
    const iconSpring = useSpring({
        rotateY: open ? 0 : 180
    })

    const renderMenu = () => {
        return pages.map(({ icon, label, href }) => {
            return (
                <Button
                    className={menuButton}
                    color={"inherit"}
                    component={RouterLink}
                    key={label}
                    onClick={() => setOpen(false)}
                    to={href ?? ''}
                >
                    {icon}{label}
                </Button>
            )
        })
    }

    return (
        <AnimatedStickyBox
            className={drawer}
            offsetTop={20}
            style={drawerSpring}
        >
            {[
                <Button
                    className={menuButton}
                    color={"inherit"}
                    key={"close"}
                    onClick={() => setOpen(!open)}>
                    {<AnimatedChevronLeft style={iconSpring} />}{"Close"}
                </Button>,
                ...renderMenu()
            ]}
        </AnimatedStickyBox>
    )
}
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Route, Routes } from "react-router-dom";
import Drawer from "./ui/Drawer";
import { Grid } from "@mui/material";

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'flex-start',
    display: 'flex'
  },
  page: {
    backgroundColor: "#333",
    height: "100vh",
  },
}));

function App() {
  const { container, page } = useStyles();

  return (
    <div className={page}>
      <Grid className={container} container direction={"row"} spacing={2}>
        <Grid item xs={0} sm={2}>
          <Drawer />
        </Grid>
        <Grid item>
          <Routes>
            <Route path="/" element={'Home'} />
            <Route path="/cv" element={'CV'} />
          </Routes>
        </Grid>
        <Grid item xs={0} sm={2} />
      </Grid>
    </div>
  );
}

export default App;
